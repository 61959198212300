import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectEveApiPageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { List, ListItem, ListItemText } from '../../components/List';
import { Link } from '../../components/Link';

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
}));

export type TProjectEveApiP = {
    data: GQLProjectEveApiPageQuery;
};

function ProjectEveApi(props: TReactFCP<TProjectEveApiP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml }: GQLProjectEveApiPageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectEveApiPageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                <Link variant='external' to='https://www.eveonline.com/'>EVE Online</Link> is unique in the way that it
                approaches its player base. The time, energy, effort, and dedication a player must have is immense. To
                help players handle the scale of the learning curve,{` `}
                <Link variant='external' to='https://www.ccpgames.com/'>CCP Games</Link> developed a set of XML and
                JSON based endpoints to serve as a gateway for players to plan and maintain their characters. When
                combined with the{` `}
                <Link variant='external' to='http://community.eveonline.com/community/fansites/toolkit'>
                    Fansite Toolkit
                </Link>
                {` `}these API's can be used to mine in-game data and process it to
            </Typography>
            <List paragraph>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            Create{` `}
                            <Link variant='external' to='https://evemondevteam.github.io/evemon/'>
                                character management
                            </Link>
                            {` `}tools
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            Build <Link variant='external' to='https://zkillboard.com/'>kill leaderboards</Link> for
                            every pilot in the game.
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={(
                            <React.Fragment>
                                Develop{` `}
                                <Link variant='external' to='http://www.eve-cost.eu/'>
                                    industrial supply chain applications
                                </Link>
                                {` `}for the production of any item
                            </React.Fragment>
                        )}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            Discover <Link variant='external' to='https://evemarketer.com/'>market insights</Link>
                            {` `}while trading items across the universe
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Discover spies within large in-game corporations`} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Do pretty much anything else`} />
                </ListItem>
            </List>
            <Typography paragraph>
                EVE API is meant to be the backbone of these types of applications and responsible for transforming the
                information obtained from the{` `}
                <Link variant='external' to='https://developers.eveonline.com/'>game's API endpoints</Link> into usable
                objects. It is intended to be free, distributed, and used with other third party Mac tools developed by
                the EVE Online player base.
            </Typography>
            <Typography paragraph>
                The most widely used and respected tool is called{` `}
                <Link variant='external' to='https://evemondevteam.github.io/evemon/'>EVEMon</Link>{` `}
                and is entirely focused on character management. Players can create skill plans, manage their finances,
                read "eve-mail", manage their assets, and more for every character they have. Its only downside is
                being Windows-only. Several attempts to bring an EVEMon-like experience to the Mac have been tried and
                abandoned over the years.
            </Typography>
            <Typography paragraph>
                Development began in 2013 during the period of time when I was playing solely on a Macbook Pro and
                wanted a tool similar to EVEMon. As the project grew and my interests changed, the motivation
                for EVE API drastically pivoted towards a tool to give me the edge I needed for
                trading items on the open market. A Bloomberg Terminal for EVE Online if you will. I had always
                struggled to earn the in-game money I needed to play the game the way that I wanted so I was dead set on
                building a industrial and trading empire to become a multi-billionaire.
            </Typography>
            <Typography paragraph>
                Sometime during development of the library I found a web-based tool that met my industrial and
                trading needs that helped me reach my financial goals. By 2015 I had enough in-game curency to{` `}
                <Link variant='external' to='https://secure.eveonline.com/PLEX/'>
                    pay for five years of my subscripion
                </Link>
                {` `}for two accounts. Because of this my interest in this project waned and I made the tough call to
                stop app development and game play to pursue other interests.
            </Typography>
            <Typography paragraph>
                While it wasn't completed, building this API did help me jump feet-first into product
                development, research, customer interaction, and app development within the Apple App ecosystem — a
                great introduction to the skills I’d need as a budding entrepreneur.
            </Typography>
            <Typography paragraph>
                I don't play Eve Online anymore, but if I pick it back up in the future I will no doubt try to
                revitalize this project and get it published!
            </Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectEveApiP>): TReactFCR => (
    <Page component={ProjectEveApi} cprops={props} />
);

export const query = graphql`
    query ProjectEveApiPage {
        sitePage(internalComponentName: {eq: "ComponentProjectsEveApi"}) {
            path
        }
        projectsYaml(pid: {eq: "eve-api"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
